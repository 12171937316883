/* eslint-disable */
type CrTimestamp = number;
type CrJson = any;
type CrBigInt = number;
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetShopUxCollectionDetail
// ====================================================

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_product {
  shop_product_no: string | null;
  catalog_product_id: string | null;
  shop_id: number;
  url: string;
  image_url: string;
  webp_image_url: string | null;
  name: string;
  price: number;
  discount_rate: number | null;
  shipping_fee_type: ShopUxShippingFeeType | null;
  sales_status: ShopUxSalesStatus | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_discount_info {
  image_url: string | null;
  color: string | null;
  title: string | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_badge_list_image_size | null;
  small_image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_badge_list_small_image_size | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
  /**
   * 카테고리 키
   */
  key: string;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_brand_name_badge_list_image_size | null;
  small_image_size: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_brand_name_badge_list_small_image_size | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_one_day_delivery_color {
  normal: string | null;
  dark: string | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_one_day_delivery_html {
  normal: string | null;
  dark: string | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_one_day_delivery {
  text: string;
  color: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_one_day_delivery_color | null;
  html: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_one_day_delivery_html | null;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list {
  product: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_product;
  /**
   * 스토어 명 (있을시에만 노출)
   */
  shop_name: string | null;
  browsing_type: ShopUxBrowsingType;
  /**
   * Z결제 단독 할인 적용 여부
   */
  is_zpay_discount: boolean;
  /**
   * Z결제 할인과 쿠폰 할인이 적용된 최종 금액
   */
  final_price: number;
  /**
   * 할인율을 극대화하기 위해 설정된 최대금액
   */
  max_price: number;
  /**
   * Z결제 미지원 여부
   */
  is_display_not_zpay: boolean;
  /**
   * 리뷰 개수
   */
  review_count: string | null;
  /**
   * 리뷰 평점
   */
  review_score: number | null;
  /**
   * 순위
   */
  ranking: number | null;
  discount_info: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_discount_info | null;
  /**
   * 뱃지 목록
   */
  badge_list: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_badge_list[] | null;
  managed_category_list: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_managed_category_list[] | null;
  /**
   * FOMO
   */
  fomo: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_fomo | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_thumbnail_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트
   */
  metadata_emblem_badge_list: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_metadata_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 스토어명 우측 영역 뱃지 리스트
   */
  brand_name_badge_list: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_brand_name_badge_list[] | null;
  /**
   * 당일 배송
   */
  one_day_delivery: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list_one_day_delivery | null;
  /**
   * 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요)
   */
  is_plp_v2: boolean;
}

export interface GetShopUxCollectionDetail_getShopUxCollectionDetail {
  /**
   * 컬렉션 ID
   */
  id: string;
  /**
   * 컬렉션 타이틀
   */
  title: string;
  /**
   * 컬렉션 설명
   */
  description: string | null;
  /**
   * 컬렉션 이미지 URL 목록
   */
  image_url_list: string[];
  /**
   * theme 정보
   */
  store_home_theme: string | null;
  /**
   * 상품 목록
   */
  product_list: GetShopUxCollectionDetail_getShopUxCollectionDetail_product_list[];
}

export interface GetShopUxCollectionDetail {
  /**
   * 컬렉션을 상세 조회한다
   */
  getShopUxCollectionDetail: GetShopUxCollectionDetail_getShopUxCollectionDetail;
}

export interface GetShopUxCollectionDetailVariables {
  shop_id: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxBadge
// ====================================================

export interface ShopUxBadge_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxBadge_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxBadge {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: ShopUxBadge_image_size | null;
  small_image_size: ShopUxBadge_small_image_size | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ShopUxBrowsingType {
  DETAIL_BROWSER = "DETAIL_BROWSER",
  INTERNAL_BROWSER = "INTERNAL_BROWSER",
  NATIVE_BROWSER = "NATIVE_BROWSER",
  NORMAL = "NORMAL",
  Z_BROWSER = "Z_BROWSER",
}

/**
 * 판매상태
 */
export enum ShopUxSalesStatus {
  CLOSED = "CLOSED",
  ON_SALE = "ON_SALE",
  PREPARING = "PREPARING",
  SOLD_OUT = "SOLD_OUT",
  SUSPENDED = "SUSPENDED",
}

/**
 * 배송비 종류
 */
export enum ShopUxShippingFeeType {
  FREE_SHIPPING = "FREE_SHIPPING",
  SHOP_POLICY = "SHOP_POLICY",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
