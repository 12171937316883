import compact from 'lodash/compact';

import { UxGoodsCardItemForHorizontalProductCard, UxGoodsCardItemForVerticalProductCard } from '@widgets/product-card';

import {
  CollectionProductItemType,
  ShopUxProductCardItem,
  ShopUxProductCardItem_badge_list,
  ShopUxShippingFeeType,
} from '../types';

type UxGoodsCardItemListForStoreHome =
  | UxGoodsCardItemForVerticalProductCard[]
  | UxGoodsCardItemForHorizontalProductCard[];

export const convertProductListToIdList = (product_list: UxGoodsCardItemListForStoreHome) => {
  return compact(product_list.map(({ catalog_product_id }) => catalog_product_id));
};

export const getProductCardBadgeImageList = (badge_list?: ShopUxProductCardItem_badge_list[] | null): string[] => {
  return (badge_list ?? []).map((badge) => badge.image_url);
};

export function convertProductItemListToUxGoodsCardItemList<
  T extends CollectionProductItemType | ShopUxProductCardItem,
>({
  item_list,
  is_zigzag,
  show_ranking,
}: {
  item_list: T[];
  is_zigzag: boolean;
  show_ranking: boolean;
}): UxGoodsCardItemListForStoreHome {
  return item_list.map(
    ({
      max_price,
      final_price,
      browsing_type,
      is_display_not_zpay,
      shop_name,
      product: {
        url: product_url,
        image_url,
        webp_image_url,
        name: title,
        discount_rate,
        price,
        shipping_fee_type,
        shop_id,
        shop_product_no,
        catalog_product_id,
        sales_status,
      },
      review_count: display_review_count,
      review_score,
      discount_info,
      badge_list,
      fomo,
      ranking,
      thumbnail_nudge_badge_list,
      thumbnail_emblem_badge_list,
      brand_name_badge_list,
      managed_category_list,
      one_day_delivery,
    }) => ({
      catalog_product_id,
      image_url,
      webp_image_url,
      shop_name,
      title,
      price,
      max_price,
      final_price,
      discount_rate,
      discount_info,
      shop_id: String(shop_id),
      shop_product_no,
      product_url,
      zpay: !is_display_not_zpay,
      free_shipping: shipping_fee_type === ShopUxShippingFeeType.FREE_SHIPPING,
      managed_category_list,
      sales_status,
      browsing_type,
      review_score,
      display_review_count,
      thumbnail_nudge_badge_list,
      thumbnail_emblem_badge_list,
      badge_list,
      brand_name_badge_list,
      one_day_delivery,
      ...(show_ranking && { ranking }),
      ...(is_zigzag && { fomo }),
    }),
  );
}
